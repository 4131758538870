import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('here??')
  }

  onClose(e) {
    $('#exit-overlay').show();
  }

  disconnect() {
  }
}
