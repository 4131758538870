import jQuery from 'jquery'
import "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import bootstrap from 'bootstrap'
import iziToast from 'izitoast'

import mixpanel from 'mixpanel-browser'

import { AuthPopup } from '../src/mansa/mansa-sdk.popup'

import 'jquery.nicescroll'
// import 'jqvmap'
import 'summernote/dist/summernote-bs4'
import 'selectric'
// import 'owl.carousel/dist/owl.carousel.js'
// import 'owl.carousel';

import 'select2'
// require("select2")

import 'bootstrap/dist/css/bootstrap.min.css'
import 'select2/dist/css/select2'
// import 'jqvmap/dist/jqvmap.css'
import 'summernote/dist/summernote-bs4.css'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'selectric/public/selectric.css'
import 'izitoast/dist/css/iziToast.min.css'
import 'animate.css';


global.$ = jQuery
global.jQuery = jQuery
global.iziToast = iziToast

global.Rails = Rails

// Turbolinks.start()
Rails.start()

Rails.delegate(document, Rails.linkDisableSelector,   "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", Rails.enableElement)

Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-start", Rails.disableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:before-cache", Rails.enableElement)

global.mixpanel = mixpanel
mixpanel.init(gon.mixpanel_project_token, {debug: gon.mixpanel_debug_mode, ignore_dnt: true })

global.authPopup = new AuthPopup()

// const application = Application.start()
// const context = require.context("../src/mansa/controllers", true, /\.js$/)
// application.load(definitionsFromContext(context))

const application = Application.start()
const context = require.context("../src/mansa/controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}


$(function(){
  // always pass csrf tokens on ajax calls
  $.ajaxSetup({
    beforeSend: (xhr) => {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    }
  });
});

document.addEventListener('turbo:load', function() {
  if (window.opener !== null) {
    $('.mansa-start-close').show()
    $('.mansa-close').show()
  } else {
    $('.mansa-start-close').hide()
    $('.mansa-close').hide()
  }
});

document.addEventListener('turbo:submit-start', function(e) {
  if (e.target.dataset.hasOverlay) {
    $('#loading-overlay').show();
  }
});

document.addEventListener('turbo:submit-end', function(e) {
  if (e.target.dataset.hasOverlay) {
    $('#loading-overlay').hide();
  }
});

window.dispatchGooglePickerEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-picker-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

var delegate,
  slice = [].slice;

delegate = function(eventName, arg) {
  var handler, parameters;
  parameters = arg.parameters;
  handler = function() {
    var args, data, delegatedEvent, i, index, len, name;
    args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    data = {};
    for (index = i = 0, len = parameters.length; i < len; index = ++i) {
      name = parameters[index];
      data[name] = args[index];
    }
    // console.log('firing ' + "jquery:" + eventName)
    delegatedEvent = new CustomEvent("jquery:" + eventName, {
      bubbles: true,
      cancelable: true,
      detail: data
    });
    return data.event.target.dispatchEvent(delegatedEvent);
  };
  return $(document).on(eventName, handler);
};
delegate('change', { parameters: ['event'] });
delegate('select2:selecting', { parameters: ['event'] });
delegate('select2:select', { parameters: ['event'] });
delegate('select2:unselecting', { parameters: ['event'] });
delegate('select2:unselect', { parameters: ['event'] });

