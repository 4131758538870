import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('here??')
  }

  onCancel(e) {
    this.element.style.display = 'none';
  }

  disconnect() {
  }
}
